import { $, $$ } from '../helpers/query-selector.js';
import { gsap, Draggable, InertiaPlugin } from 'gsap/all';

gsap.registerPlugin(Draggable, InertiaPlugin);

function init() {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        // Move slideshow if user has not interacted
        const target = entry.target;

        if (
          entry.isIntersecting &&
          !target.classList.contains('has-interacted')
        ) {
          gsap.to(target, {
            scrollLeft: window.innerWidth * 0.05,
            duration: 1,
            ease: 'sine.inOut'
          });

          observer.unobserve(target);
        }
      }
    },
    { rootMargin: '0% 0% -90% 0%' }
  );

  const slideshows = $$('.js-slideshow');

  for (const slideshow of slideshows) {
    const wrapper = $('.js-slideshow-wrapper', slideshow);

    Draggable.create(wrapper, {
      type: 'scrollLeft',
      bounds: slideshow,
      edgeResistance: 0.8,
      inertia: true,
      zIndexBoost: false,
      onDragStart: () => {
        wrapper.classList.add('has-interacted');
      }
    });

    observer.observe(wrapper);
  }
}

export default init;
