import { $, $$ } from '../helpers/query-selector.js';

function loadScripts() {
  const scripts = $$('script[type="text/plain"]');

  for (const script of scripts) {
    // Create new script
    const newScript = document.createElement('script');
    newScript.innerHTML = script.innerHTML;

    // Merge attributes
    const attributes = Array.prototype.slice.call(script.attributes);
    for (const attr of attributes) {
      if (attr.name === 'type' || attr.name === 'data-consent-required')
        continue;
      newScript.setAttribute(attr.name, attr.value);
    }

    // Add to head
    document.head.removeChild(script);
    document.head.appendChild(newScript);
  }
}

function init() {
  if (document.cookie.indexOf('cookies-declined=true') > -1) {
    return;
  } else if (document.cookie.indexOf('cookies-accepted=true') > -1) {
    loadScripts();
  } else {
    const cookieConsent = $('.js-cookie-consent');
    const buttons = $$('.js-cookie-consent-button', cookieConsent);
    const visibleClass = 'c-cookie-consent--visible';

    // Create cookie string, expires after 1 year (Safari after 7 days)
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    const cookieString = `=true; expires=${cookieDate.toUTCString()}; path=/`;

    // Fade in cookie consent
    cookieConsent.classList.add(visibleClass);

    // Add button event listeners
    for (const button of buttons) {
      button.addEventListener('click', () => {
        if (button.hasAttribute('data-accept')) {
          document.cookie = 'cookies-accepted' + cookieString;
          loadScripts();
        } else if (button.hasAttribute('data-decline')) {
          document.cookie = 'cookies-declined' + cookieString;
        }

        cookieConsent.classList.remove(visibleClass);
      });
    }
  }
}

export default init;
