import debounce from '../helpers/debounce.js';

function lerp(a, b, n) {
  return (1 - n) * a + n * b;
}

function detectWebPSupport() {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src =
      'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
  });
}

function getSpritesheetTexture(name, resources) {
  for (const [key, resource] of Object.entries(resources)) {
    if (!resource.spritesheet) continue;

    const texture = resource.spritesheet.textures[name];
    if (texture) return texture;
  }
}

function getMousePosition(app) {
  let w, h;
  let mouse = { x: 0, y: 0, relX: 0, relY: 0, continuousAnimation: false };

  function setSizeVars() {
    w = app.screen.width;
    h = app.screen.height;
  }

  setSizeVars();
  window.addEventListener('resize', debounce(setSizeVars));
  window.addEventListener('orientationchange', debounce(setSizeVars));

  // Get mouse position
  function setMousePosition(clientX, clientY) {
    mouse.x = clientX;
    mouse.y = clientY + window.pageYOffset;
    mouse.relX = mouse.x / w - 0.5;
    mouse.relY = mouse.y / h - 0.5;
  }

  app.view.addEventListener('mousemove', e => {
    mouse.continuousAnimation = true;
    setMousePosition(e.clientX, e.clientY);
  });

  // Get touch position
  const touchEvents = ['touchstart', 'touchmove', 'touchend', 'touchcancel'];

  for (const type of touchEvents) {
    app.view.addEventListener(
      type,
      e => {
        // e.preventDefault();
        mouse.continuousAnimation =
          type === 'touchstart' || type === 'touchmove';

        if (e.touches[0]) {
          setMousePosition(e.touches[0].clientX, e.touches[0].clientY);
        }
      },
      { passive: true } // set to false because of e.preventDefault()
    );
  }

  return mouse;
}

export { lerp, detectWebPSupport, getSpritesheetTexture, getMousePosition };
