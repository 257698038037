import { $ } from '../helpers/query-selector.js';

function clearFormSessionStorage(form) {
  const key = $('input[name="m"]', form).value;
  sessionStorage.removeItem(key);
}

function initFormSessionStorage(form) {
  const key = $('input[name="m"]', form).value;

  if (sessionStorage.getItem(key)) {
    const formData = JSON.parse(sessionStorage.getItem(key));
    for (const [name, value] of formData) {
      if (name === 'm' || name === 'website') continue;

      const input = $(`input[name="${name}"]`, form);
      if (!input) continue;
      if (input.type === 'checkbox' || input.type === 'radio') continue;

      input.value = value;
    }
  }

  form.addEventListener('change', () => {
    let formData = new FormData(form);
    formData = [...formData.entries()];
    sessionStorage.setItem(key, JSON.stringify(formData));
  });
}

export { initFormSessionStorage, clearFormSessionStorage };
