import { $, $$ } from '../helpers/query-selector.js';
import { reducedMotion } from '../helpers/variables';
import initPageTransition from './artist-page-transition.js';
import { gsap, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const teasers = $$('.js-artist-teaser');

const mouse = {
  x: 0,
  y: 0
};

const minDistance = window.innerWidth * 0.33;
const translateFactor = 0.4;
const captionVisibleClass = 'c-artist-teaser--caption-visible';

function render() {
  for (const teaser of teasers) {
    // Only animate teasers in viewport
    if (!teaser.inView) continue;

    // Calculate distances
    const rect = teaser.getBoundingClientRect();

    const oX = rect.x + rect.width / 2;
    const oY = rect.y + rect.height / 2;

    const deltaX = oX - mouse.x;
    const deltaY = oY - mouse.y;

    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    teaser.distance = distance; // for z-index order

    // Calculate transform factors and caption position
    const transform = {
      scale: 0,
      x: 0,
      y: 0,
      captionX: 0,
      captionY: 0
    };

    // Image scale and position
    if (distance < minDistance) {
      const relDistance = 1 - distance / minDistance;

      transform.scale = relDistance;
      transform.x = -deltaX * translateFactor * relDistance;
      transform.y = -deltaY * translateFactor * relDistance;
    } else {
      transform.scale = 0;
      transform.x = 0;
      transform.y = 0;
    }

    // Caption position
    if (teaser.classList.contains(captionVisibleClass)) {
      transform.captionX = -deltaX;
      transform.captionY = -deltaY + 50;
    } else {
      transform.captionX = 0;
      transform.captionY = 0;
    }

    // Set css variables
    teaser.style.setProperty('--scale-factor', transform.scale);
    teaser.style.setProperty('--x', transform.x + 'px');
    teaser.style.setProperty('--y', transform.y + 'px');
    teaser.style.setProperty('--caption-x', transform.captionX + 'px');
    teaser.style.setProperty('--caption-y', transform.captionY + 'px');
  }

  // Update z-index
  const orderedTeasers = teasers;
  orderedTeasers.sort((a, b) => b.distance - a.distance);

  orderedTeasers.forEach((teaser, i) => {
    teaser.style.setProperty('z-index', i);
  });

  // Animation loop
  requestAnimationFrame(render);
}

function init() {
  if (reducedMotion.matches) return;

  initPageTransition();

  // If no device input supports hover
  if (!window.matchMedia('(any-hover:hover)').matches) {
    const images = $$('.l-free-image-text .c-image__wrapper');

    for (const image of images) {
      image.style.setProperty('outline', '1px solid transparent');

      gsap.fromTo(
        image,
        {
          scale: 1.1,
          x: (i, el) => {
            const imgRect = el.parentNode.getBoundingClientRect();
            const imgCenter = imgRect.x + imgRect.width / 2;
            const offsetFactor = (imgCenter / window.innerWidth) * 2 - 1;

            return 5 * offsetFactor + 'rem';
          }
        },
        {
          scrollTrigger: {
            trigger: image.parentNode,
            start: 'top bottom',
            end: 'bottom 30%',
            scrub: 0.25
          },
          scale: 1,
          x: 0,
          ease: 'power0.easeNone'
        }
      );
    }
    return;
  }

  // Detect if teaser is in viewport
  const observer = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        entry.target.inView = entry.isIntersecting;
      }
    },
    { rootMargin: '10% 0% 10% 0%' }
  );

  for (const teaser of teasers) {
    teaser.addEventListener('mouseenter', () => {
      teaser.classList.add(captionVisibleClass);
    });

    teaser.addEventListener('mouseleave', () => {
      teaser.classList.remove(captionVisibleClass);
    });

    observer.observe(teaser);
  }

  document.addEventListener('mousemove', e => {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
  });

  requestAnimationFrame(render);
}

export default init;
