import { $ } from '../helpers/query-selector.js';
import { gsap, ScrollTrigger } from 'gsap/all';
import { reducedMotion, viewport } from '../helpers/variables';

gsap.registerPlugin(ScrollTrigger);

function init() {
  const artistHero = $('.js-artist-hero');
  const image = $('.js-artist-hero .c-image');
  const frame = $('.js-artist-frame-left');
  const hl = $('.js-artist-hl');

  if (!artistHero || reducedMotion.matches) return;

  const scrollTriggerOptions = {
    trigger: image,
    scrub: true,
    invalidateOnRefresh: true,
    start: 'top top',
    end: 'bottom-=7% top'
  };

  gsap.fromTo(
    frame,
    { x: '-100%' },
    {
      scrollTrigger: scrollTriggerOptions,
      x: 0,
      ease: 'power1.out'
    }
  );

  gsap.fromTo(
    image,
    { x: 0 },
    {
      scrollTrigger: scrollTriggerOptions,
      x: () => {
        let x = 0;

        if (viewport.m.matches) x = '7.5rem';
        else if (viewport.s.matches) x = '2.5rem';

        return x;
      },
      ease: 'power1.out'
    }
  );

  gsap.fromTo(
    hl,
    { opacity: 1, y: 0 },
    {
      scrollTrigger: {
        ...scrollTriggerOptions,
        end: 'top+=35% top'
      },
      opacity: 0,
      y: () => {
        return viewport.s.matches ? 0 : '2.5rem';
      },
      ease: 'power1.out'
    }
  );
}

export default init;
