import { $, $$ } from '../helpers/query-selector.js';
import { gsap, Flip } from 'gsap/all';
import initArtistHero from './artist-hero.js';

gsap.registerPlugin(Flip);

const teasers = $$('.js-artist-teaser');
const header = $('.js-header');
const main = $('.js-main');
const body = document.body;

async function transitionToPage(teaser) {
  // Fetch artist page
  const response = await fetch(teaser.href);
  const html = await response.text();

  // Clone image for easier animation
  const image = $('.c-image__wrapper', teaser);
  const state = Flip.getState(image);

  const clonedImage = image.cloneNode(true);

  clonedImage.style.setProperty('position', 'absolute');
  clonedImage.style.setProperty('top', `${window.pageYOffset}px`);
  clonedImage.style.setProperty('left', '0px');
  clonedImage.style.setProperty('width', '100%');
  clonedImage.style.setProperty('height', 'auto');
  clonedImage.style.setProperty('z-index', '16');

  body.appendChild(clonedImage);

  // Animate image
  const transitionDuration = 0.6;

  Flip.from(state, {
    targets: clonedImage,
    duration: transitionDuration,
    ease: 'power2.inOut',
    scale: false
  });

  // Fade out other elements
  await gsap.to([header, main], { opacity: 0, duration: transitionDuration });

  // Parse html
  const frag = document.createRange().createContextualFragment(html);
  const newHeader = $('.js-header', frag);
  const newMain = $('.js-main', frag);

  // Replace new image with cloned image
  clonedImage.removeAttribute('style');
  let img = $('img', clonedImage);
  img.classList.remove('lazyloaded');

  const newImage = $('.js-artist-hero .c-image__wrapper', newMain);
  newImage.replaceWith(clonedImage);

  // Replace header and main
  header.replaceWith(newHeader);
  main.replaceWith(newMain);
  window.scrollTo(0, 0);

  // Update <head>
  const elements = [
    'title',
    'link[rel="canonical"]',
    'meta[property="og:url"]',
    'meta[property="og:title"]',
    'meta[property="og:description"]',
    'meta[property="og:image"]',
    'meta[property="og:image:width"]',
    'meta[property="og:image:height"]',
    'meta[property="og:image:alt"]',
    'meta[name="robots"]'
  ];

  for (const element of elements) {
    const oldNode = $(element, document.head);
    const newNode = $(element, frag);

    if (oldNode && newNode) oldNode.replaceWith(newNode);
  }

  // Load higher res image
  img = $('.js-artist-hero .c-image__wrapper img');
  img.style.setProperty('opacity', 1);
  img.classList.add('lazyload');

  // Fade in new elements
  const closeButton = $('.js-close-button');
  const hl = $('.js-artist-hl');
  const content = $('.js-artist-content');

  await gsap.from([closeButton, hl, content], {
    opacity: 0,
    duration: transitionDuration
  });

  initArtistHero();

  // Update history
  history.replaceState(null, null, teaser.href);

  // Move focus
  body.setAttribute('tabindex', '-1');
  body.focus({ preventScroll: true });
  body.removeAttribute('tabindex');
}

function init() {
  for (const teaser of teasers) {
    teaser.addEventListener('click', e => {
      e.preventDefault();
      transitionToPage(teaser);
    });
  }
}

export default init;
