import { $, $$ } from '../helpers/query-selector.js';
import { gsap, ScrollToPlugin } from 'gsap/all';
import {
  initFormSessionStorage,
  clearFormSessionStorage
} from './form-session-storage.js';

gsap.registerPlugin(ScrollToPlugin);

const root = $('.js-dummy-order-form');
const form = $('form', root);
const formSuccessClass = 'l-dummy-order-form--success';
const countrySelect = $('.js-dummy-order-form-country-select select');
const customCountryOption = $(
  '.js-dummy-order-form-country-select option:last-child'
);
const customCountry = $('.js-dummy-order-form-custom-country');
const customCountryInput = $('.js-dummy-order-form-custom-country input');
const customCountryClassName = 'c-input--custom-country';
const honeypot = $('.js-dummy-order-form-hnpt input', root);
const submitButton = $('.js-dummy-order-form-submit', root);
const submitButtonLoadingClass = 'c-button--loading';
const errorEl = $('.js-dummy-order-form-error', root);
const errorElActiveClass = 'l-dummy-order-form__error--visible';
const successEl = $('.js-dummy-order-form-success', root);
let submitInProgress = false;

function trackSubmitEvent() {
  if (!window.gtag) return;

  gtag('event', form.dataset.gtagName, {
    event_category: form.dataset.gtagCategory
  });
}

function validateForm() {
  const requiredInputs = $$('input[required]', form);
  let valid = true;

  for (const input of requiredInputs) {
    if (!input.validity.valid) valid = false;
  }

  return valid;
}

async function submitForm() {
  const formData = new FormData(form);
  formData.append('submit', 'submit');

  return new Promise(async (resolve, reject) => {
    // Simple client side spam prevention, not an ideal solution
    if (honeypot.value !== '') {
      reject();
    } else {
      fetch(form.action, {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });

      resolve();
    }
  });
}

function init() {
  // form only works with js enabled to prevent spam
  form.setAttribute('action', form.dataset.action);
  // submitButton.removeAttribute('disabled');
  honeypot.value = '';

  // initFormSessionStorage(form);

  // Init custom country option
  if (customCountryInput && customCountryInput) {
    customCountryInput.removeAttribute('required');

    const name = countrySelect.getAttribute('name');

    countrySelect.addEventListener('change', e => {
      if (e.target.value === 'Other') {
        customCountry.classList.remove(customCountryClassName);

        // Add required attribute and clear value
        customCountryInput.setAttribute('required', true);
        customCountryInput.value = '';
        customCountryInput.setAttribute('name', name);
        countrySelect.removeAttribute('name');
      } else {
        customCountry.classList.add(customCountryClassName);

        // Remove required attribute and clear value
        customCountryInput.removeAttribute('required');
        customCountryInput.value = '';
        customCountryInput.removeAttribute('name');
        countrySelect.setAttribute('name', name);
      }
    });
  }

  // Init submit button
  submitButton.addEventListener('click', async e => {
    // show native warnings for required fields
    if (!validateForm()) return;

    // if no input warnings, submit form via js
    e.preventDefault();

    // prevent multiple submits
    if (submitInProgress) return;
    submitInProgress = true;

    // add button loading class, remove form error class
    submitButton.classList.add(submitButtonLoadingClass);
    errorEl.classList.remove(errorElActiveClass);
    errorEl.removeAttribute('role');

    // start submit
    const submitFormPromise = submitForm();
    const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));

    try {
      await Promise.all([submitFormPromise, delayPromise]);

      submitInProgress = false;
      submitButton.classList.remove(submitButtonLoadingClass);
      root.classList.add(formSuccessClass);

      // clearFormSessionStorage(form);

      trackSubmitEvent();

      gsap.to(window, {
        duration: 0.75,
        scrollTo: { y: root, offsetY: 50 },
        onComplete: () => {
          successEl.setAttribute('role', 'alert');
        }
      });
    } catch (error) {
      submitInProgress = false;
      submitButton.classList.remove(submitButtonLoadingClass);
      errorEl.classList.add(errorElActiveClass);
      errorEl.setAttribute('role', 'alert');
    }
  });
}

export default init;
