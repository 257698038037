import { $ } from '../helpers/query-selector.js';
import onResize from '../helpers/on-resize.js';

const root = $('.js-text-images-scroll-layout');
const img = $('.js-text-images-scroll-layout-images > *', root);

function setHeight() {
  let height = img.offsetHeight;
  if (height === 0) height = window.innerHeight * 0.8;

  root.style.setProperty('--height', height + 'px');
}

function init() {
  setHeight();
  onResize(setHeight);
}

export default init;
