import { $, $$ } from '../helpers/query-selector.js';

function init() {
  const form = $('.js-dummy-discount-form');
  const submitButton = $('.js-dummy-discount-submit');
  const successElement = $('.js-dummy-discount-success');
  const errorElement = $('.js-dummy-discount-error');
  const errorClass = 'visible';

  const orderFormFieldset = $('.js-dummy-order-form-fieldset');
  const tokenInput = $('.js-dummy-order-form-token');
  const orderFormSubmit = $('.js-dummy-order-form-submit');

  let submitInProgress = false;

  function validateForm(form) {
    const requiredInputs = $$(
      'input[required], textarea[required], select[required]',
      form
    );
    let valid = true;

    for (const input of requiredInputs) {
      if (!input.validity.valid) valid = false;
    }

    return valid;
  }

  function submitForm() {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData(form);
      formData.append('submit', 'submit');

      try {
        const request = await fetch(form.action, {
          method: 'POST',
          body: formData
        });

        let response = await request.text();
        response = JSON.parse(response);

        resolve(response);
      } catch (error) {
        reject();
      }
    });
  }

  function initSubmitButton() {
    form.addEventListener('submit', async e => {
      // Show native warnings for required fields
      if (!validateForm(form)) return;

      // If no input warnings, submit form via js
      e.preventDefault();

      // Prevent multiple submits
      if (submitInProgress) return;

      submitInProgress = true;

      // Add button loading class, remove form error class
      submitButton.classList.add('c-button--loading');
      errorElement.classList.remove(errorClass);

      // Submit form
      const submitFormPromise = submitForm();
      const delayPromise = new Promise(resolve => setTimeout(resolve, 500));

      try {
        const [formResponse] = await Promise.all([
          submitFormPromise,
          delayPromise
        ]);
        submitInProgress = false;
        submitButton.classList.remove('c-button--loading');

        if (formResponse.message == 'invalid') {
          errorElement.classList.add(errorClass);
        } else if (formResponse.message == 'valid') {
          orderFormFieldset.disabled = false;
          orderFormSubmit.disabled = false;
          successElement.classList.add('visible');
          tokenInput.value = formResponse.token;

          submitButton.disabled = true;

          const discountCodeInput = $('input#discount-code');
          discountCodeInput.value = $('input[name="discount-code"]').value;
        }
      } catch (error) {}
    });
  }

  initSubmitButton();
}

export default init;
