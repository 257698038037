const images = [
  {
    name: '1.psd',
    scale: 0.1,
    x: 0.7,
    y: 0.7
  },
  {
    name: '2.psd',
    scale: 0.1,
    x: 0.11,
    y: 0.96
  },
  {
    name: '3.psd',
    scale: 0.1,
    x: 0.45,
    y: 0.85
  },
  {
    name: '4.psd',
    scale: 0.1,
    x: 0.25,
    y: 0.4
  },
  {
    name: '5.psd',
    scale: 0.16,
    x: 0.85,
    y: 0.95
  },
  {
    name: '6.psd',
    scale: 0.1,
    x: 0.92,
    y: 0.58
  },
  {
    name: '7.psd',
    scale: 0.12,
    x: 0.3,
    y: 0.88
  },
  {
    name: '8.psd',
    scale: 0.1,
    x: 0.6,
    y: 0.4
  },
  {
    name: '9.psd',
    scale: 0.1,
    x: 0.85,
    y: 0.2
  },
  {
    name: '10.psd',
    scale: 0.1,
    x: 0.3,
    y: 0.15
  },
  {
    name: '11.psd',
    scale: 0.1,
    x: 0.1,
    y: 0.5
  },
  {
    name: '12.psd',
    scale: 0.12,
    x: 0.3,
    y: 0.65
  }
];

export default images;
