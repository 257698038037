import { $, $$ } from '../helpers/query-selector.js';
import { gsap, ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

function init() {
  const form = $('.js-dummy-form');
  const submitButton = $('.js-dummy-submit');

  const insertPaperPages = $$('.js-dummy-insert-paper-pages');
  const insertPaperMaxCount = $('.js-dummy-insert-paper-max-count');

  const hero = $('.js-dummy-hero');
  const formStep1 = $('.js-dummy-form-step-1');
  const formStep2 = $('.js-dummy-form-step-2');

  function createCheckoutList(form) {
    const formData = new FormData(form);

    $('.js-checkout-quantity').textContent = formData.get('Quantity');
    $('.js-checkout-width').textContent = formData.get('Width');
    $('.js-checkout-height').textContent = formData.get('Height');
    $('.js-checkout-binding').textContent = formData.get('Binding');

    if (formData.get('Binding') === 'Folded') {
      $('.js-checkout-binding').textContent =
        formData.get('Binding') + ', ' + formData.get('Folded Binding');
    }

    if (formData.get('Cover') === 'Yes') {
      $('.js-checkout-cover dd').textContent =
        formData.get('Cover Paper') +
        ' ' +
        formData.get('Cover gm2') +
        'g/m², ' +
        formData.get('Cover Pages') +
        ' pages';
    } else {
      $('.js-checkout-cover dd').textContent = 'No';
    }

    for (const i of [1, 2, 3]) {
      if (formData.get('Insert Paper ' + i) !== null) {
        $('.js-checkout-insert-' + i).classList.remove('hidden');

        $('.js-checkout-insert-' + i + ' dd').textContent =
          formData.get('Insert Paper ' + i) +
          ' ' +
          formData.get('Insert gm2 ' + i) +
          'g/m², ' +
          formData.get('Insert Pages ' + i) +
          ' pages';
      } else {
        $('.js-checkout-insert-' + i).classList.add('hidden');
      }
    }
  }

  function updateShippingForm(form) {
    const formData = new FormData(form);

    const quantityInput = $('input#quantity');
    const formatInput = $('input#format');
    const bindingInput = $('input#binding');
    const coverInput = $('input#cover');

    quantityInput.value = formData.get('Quantity');

    formatInput.value =
      formData.get('Width') + ' × ' + formData.get('Height') + ' mm';

    bindingInput.value = formData.get('Binding');

    if (formData.get('Binding') === 'Folded') {
      bindingInput.value += ', ' + formData.get('Folded Binding');
    }

    if (formData.get('Cover') === 'Yes') {
      coverInput.value =
        formData.get('Cover Paper') +
        ' ' +
        formData.get('Cover gm2') +
        'g/m², ' +
        formData.get('Cover Pages') +
        ' pages';
    } else {
      coverInput.value = 'No Cover';
    }

    for (const i of [1, 2, 3]) {
      if (formData.get('Insert Paper ' + i) !== null) {
        $('input#insert-' + i).value =
          formData.get('Insert Paper ' + i) +
          ' ' +
          formData.get('Insert gm2 ' + i) +
          'g/m², ' +
          formData.get('Insert Pages ' + i) +
          ' pages';
      }
    }
  }

  function validateForm(form) {
    const requiredInputs = $$(
      'input[required], textarea[required], select[required]',
      form
    );
    let valid = true;

    for (const input of requiredInputs) {
      if (!input.validity.valid) valid = false;
    }

    return valid;
  }

  submitButton.addEventListener('click', async e => {
    // Show native warnings for required fields
    if (!validateForm(form)) return;

    let insertPaperNumber = 0;

    insertPaperPages.forEach(el => {
      if (!el.closest('.js-dummy-paper-input').classList.contains('hidden')) {
        insertPaperNumber += parseInt(el.value) || 0;
      }
    });

    insertPaperMaxCount.classList.add('hidden');

    if (insertPaperNumber > 500) {
      insertPaperMaxCount.classList.remove('hidden');
      e.preventDefault();
      return;
    }

    e.preventDefault();

    createCheckoutList(form);
    updateShippingForm(form);

    // Set visibility of the form steps
    hero.classList.add('hidden');
    formStep1.classList.add('hidden');
    formStep2.classList.remove('hidden');

    gsap.to(window, {
      duration: 0.75,
      scrollTo: { y: formStep2, offsetY: 50 }
    });
  });

  const editButton = $('.js-dummy-edit-button');

  editButton.addEventListener('click', () => {
    hero.classList.remove('hidden');
    formStep1.classList.remove('hidden');
    formStep2.classList.add('hidden');

    gsap.to(window, {
      duration: 0.75,
      scrollTo: { y: $('form', formStep1), offsetY: 50 }
    });
  });

  // Binding
  const binding = $('.js-dummy-binding');
  const foldedBinding = $('.js-dummy-folded-binding');
  const foldedBindingRadios = $$(
    '.js-dummy-folded-binding input[type="radio"]'
  );
  const foldedBindingLabel = $('.js-dummy-folded-binding-label');

  foldedBindingRadios.forEach(radio => {
    radio.addEventListener('change', () => {
      foldedBindingLabel.textContent = radio.value;
    });
  });

  binding.addEventListener('change', () => {
    if (binding.value === 'Folded') {
      foldedBinding.classList.remove('hidden');

      foldedBindingRadios[0].checked = true;
      foldedBindingLabel.textContent = foldedBindingRadios[0].value;
    } else {
      foldedBinding.classList.add('hidden');

      foldedBindingRadios.forEach(radio => {
        radio.checked = false;
      });
    }

    // Insert paper pages step
    if (binding.value === 'Folded' || binding.value === 'Stapled') {
      insertPaperPages.forEach(el => {
        el.setAttribute('step', 4);
        el.setAttribute('min', 4);
      });
    } else {
      insertPaperPages.forEach(el => {
        el.setAttribute('step', 2);
        el.setAttribute('min', 2);
      });
    }
  });

  // Cover
  const coverToggle = $$('.js-dummy-cover-toggle input[type="radio"]');
  const coverPaper = $('.js-dummy-cover-paper');
  const coverPaperInputs = $$('input, select', coverPaper);

  coverToggle.forEach(toggle => {
    toggle.addEventListener('change', () => {
      if (toggle.value === 'Yes') {
        coverPaper.classList.remove('hidden');

        coverPaperInputs.forEach(input => {
          input.setAttribute('required', 'required');
        });
      } else {
        coverPaper.classList.add('hidden');

        coverPaperInputs.forEach(input => {
          input.removeAttribute('required');
          input.value = '';
        });
      }
    });
  });

  // Paper input
  const paperInputs = $$('.js-dummy-paper-input');

  for (const paperInput of paperInputs) {
    const paper = $('.js-dummy-paper-input-paper', paperInput);
    const gm2 = $('.js-dummy-paper-input-gm2', paperInput);
    const options = $$(
      '.js-dummy-paper-input-gm2 option.av-white-only',
      paperInput
    );

    paper.addEventListener('change', () => {
      if (paper.value === 'Arctic Volume Ice') {
        options.forEach(option => {
          option.disabled = true;
        });

        gm2.value = '';
      } else {
        options.forEach(option => {
          option.disabled = false;
        });

        gm2.value = '';
      }
    });
  }

  // Inserts
  const moreButton = $('.js-dummy-insert-paper-more');

  let index = 1;

  moreButton.addEventListener('click', () => {
    index++;
    const insertPaperInput = $(
      '.js-dummy-insert-paper .js-dummy-paper-input[data-index="' + index + '"]'
    );
    insertPaperInput.classList.remove('hidden');

    const inputs = $$('input, select', insertPaperInput);

    inputs.forEach(input => {
      input.setAttribute('required', 'required');
    });

    if (index >= 3) {
      moreButton.disabled = true;
    }
  });

  const removeButtons = $$('.js-dummy-insert-paper-remove');

  for (const removeButton of removeButtons) {
    removeButton.addEventListener('click', () => {
      const insertPaperInput = removeButton.closest('.js-dummy-paper-input');
      insertPaperInput.classList.add('hidden');

      const inputs = $$('input, select', insertPaperInput);

      inputs.forEach(input => {
        input.removeAttribute('required');
        input.value = '';
      });

      index--;

      moreButton.disabled = false;
    });
  }
}

export default init;
