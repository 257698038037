// Match scss vars
const viewport = {
  xs: window.matchMedia(`(min-width: ${360 / 16}em)`),
  s: window.matchMedia(`(min-width: ${480 / 16}em)`),
  ms: window.matchMedia(`(min-width: ${720 / 16}em)`),
  m: window.matchMedia(`(min-width: ${960 / 16}em)`),
  ml: window.matchMedia(`(min-width: ${1280 / 16}em)`),
  l: window.matchMedia(`(min-width: ${1440 / 16}em)`),
  xl: window.matchMedia(`(min-width: ${1680 / 16}em)`),
  xxl: window.matchMedia(`(min-width: ${1920 / 16}em)`)
};

const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

const saveData =
  'connection' in navigator && navigator.connection.saveData === true;

export { viewport, reducedMotion, saveData };
