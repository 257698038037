__Init__;

import './modules/lazyload.js';
import './modules/set-vh.js';
import initNav from './modules/nav.js';
import initVideo from './modules/video.js';
import { $ } from './helpers/query-selector.js';

async function init() {
  const polyfills = [];

  if (!('IntersectionObserver' in window)) {
    polyfills.push(import('intersection-observer'));
  }

  await Promise.all(polyfills);

  initNav();
  initVideo();

  if ($('.js-canvas'))
    import('./modules/canvas.js').then(module => module.default());

  if ($('.js-text-images-scroll-layout'))
    import('./modules/text-images-scroll-layout.js').then(module =>
      module.default()
    );

  if ($('.js-slideshow'))
    import('./modules/slideshow').then(module => module.default());

  if ($('.js-artist-hero'))
    import('./modules/artist-hero').then(module => module.default());

  if ($('.js-artist-teaser'))
    import('./modules/artist-teaser').then(module => module.default());

  if ($('.js-order-form'))
    import('./modules/order-form').then(module => module.default());

  if ($('.js-order-button'))
    import('./modules/order-button').then(module => module.default());

  if ($('.js-dummy')) {
    import('./modules/dummy-form').then(module => module.default());
    import('./modules/dummy-discount').then(module => module.default());
    import('./modules/dummy-order-form').then(module => module.default());
  }

  if ($('.js-cookie-consent'))
    import('./modules/cookie-consent').then(module => module.default());
}

init();
