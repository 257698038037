import { $, $$ } from '../helpers/query-selector.js';
import { reducedMotion, viewport } from '../helpers/variables';
import debounce from '../helpers/debounce.js';
import images from './canvas-images.js';
// prettier-ignore
import { lerp, getSpritesheetTexture, getMousePosition } from './canvas-helper.js';
import { Application } from '@pixi/app';
import { Renderer, BatchRenderer } from '@pixi/core';
import { TickerPlugin } from '@pixi/ticker';
import { skipHello, isWebGLSupported, clearTextureCache } from '@pixi/utils';
import { Loader } from '@pixi/loaders';
import { Sprite } from '@pixi/sprite';
import { SpritesheetLoader } from '@pixi/spritesheet';
import { Container, DisplayObject } from '@pixi/display';
import { gsap, PixiPlugin } from 'gsap/all';

// Register the plugin
gsap.registerPlugin(PixiPlugin);
const VERSION = '6.0.0';
PixiPlugin.registerPIXI({ DisplayObject, VERSION });

function init() {
  const root = $('.js-canvas');
  const hl = $('.js-canvas-hl');
  const letters = $$('.js-canvas-hl span');

  // Static image fallback
  if (!isWebGLSupported() || reducedMotion.matches) {
    root.classList.add('l-canvas--fallback');
    return;
  }

  // Register pixi plugins
  Renderer.registerPlugin('batch', BatchRenderer);
  Application.registerPlugin(TickerPlugin);
  Loader.registerPlugin(SpritesheetLoader);

  // Init pixi app
  skipHello();

  const app = new Application({
    antialias: true,
    backgroundAlpha: 0,
    resolution: Math.min(window.devicePixelRatio, 1.75), // max 1.75x resolution
    autoDensity: true,
    resizeTo: root // auto size instead of width / height
  });

  // Append app to root
  root.appendChild(app.view);

  // Init vars
  let w, h, vmin, vmax, minScaleDistance, minPositionDistance;
  let mouse = { x: 0, y: 0, relX: 0, relY: 0, continuousAnimation: false };
  let lerpFactor = 0.075;
  let textX = 0;
  let textY = 0;

  function setSizeVars(init = false) {
    w = app.screen.width;
    h = app.screen.height;
    vmin = Math.min(w, h);
    vmax = Math.max(w, h);
    minScaleDistance = vmax * 0.15;
    minPositionDistance = Math.sqrt(w * w + h * h) * 1.1;

    // Set initial mouse position to screen center
    if (init) {
      mouse.x = w / 2;
      mouse.y = h / 2;
    }
  }

  setSizeVars(true);
  window.addEventListener('resize', debounce(setSizeVars));
  window.addEventListener('orientationchange', debounce(setSizeVars));

  // Get mouse position
  mouse = getMousePosition(app);

  // Load textures
  const textures = JSON.parse(root.dataset.textures);
  const preloader = new Loader();
  preloader.add('spritesheet-1-200-webp', textures['200']);

  preloader.load((preloader, resources) => {
    // Create images
    const imageContainer = new Container();
    imageContainer.sortableChildren = true;
    app.stage.addChild(imageContainer);

    for (const img of images) {
      const texture = getSpritesheetTexture(img.name, resources);
      img.sprite = new Sprite(texture);
      img.ratio = texture.width / texture.height;
      img.sprite.anchor.set(0.5);
      imageContainer.addChild(img.sprite);

      // Set original scale and position
      img.sprite.width = img.scale * vmax;
      img.sprite.height = img.sprite.width / img.ratio;
      img.sprite.x = w * img.x;
      img.sprite.y = h * img.y;
      img.oX = w * img.x;
      img.oY = h * img.y;
    }

    // Animate hyperreal
    const hlAnim = gsap.fromTo(
      hl,
      { scale: 0.75 },
      { scale: 1, ease: 'power2.inOut', duration: 2.4 }
    );

    const letterAnim = gsap.fromTo(
      letters,
      { opacity: 0 },
      {
        opacity: 1,
        ease: 'power0.easeNone',
        duration: 0.3,
        delay: 0.5,
        stagger: 0.15
      }
    );

    // Set initial container scale and position
    const bounds = imageContainer.getBounds();
    imageContainer.scale.set(0.5);
    imageContainer.x = w / 2 - imageContainer.width / 2 - bounds.x / 2;
    imageContainer.y = h / 2 - imageContainer.height / 2 - bounds.y / 2;

    // Animate container
    const containerAnim = gsap.to(imageContainer, {
      pixi: { x: 0, y: 0, scale: 1 },
      duration: 2.6,
      ease: 'power2.inOut'
    });

    // Animate images
    const sprites = [];
    for (const img of images) {
      sprites.push(img.sprite);
    }

    const imagesAnim = gsap.fromTo(
      sprites,
      { pixi: { alpha: 0 } },
      {
        pixi: { alpha: 1 },
        ease: 'power0.easeNone',
        duration: 0.6,
        stagger: 0.1
      }
    );

    // Load large images
    let hasLoaded = false;
    clearTextureCache();

    const loader = new Loader();
    let textureSize = 1000;

    // if (viewport.l.matches) {
    //   textureSize = 3000;
    // } else
    // if (viewport.ml.matches) {
    //   textureSize = window.devicePixelRatio > 1 ? 2000 : 1500;
    // } else if (viewport.ms.matches) {
    //   textureSize = window.devicePixelRatio > 1 ? 1500 : 1000;
    // }

    loader.add(`spritesheet-1-${textureSize}-webp`, textures[`${textureSize}`]);

    loader.load(async (loader, resources) => {
      await Promise.all([containerAnim, hlAnim, letterAnim, imagesAnim]);

      for (const img of images) {
        img.sprite.texture = getSpritesheetTexture(img.name, resources);
      }

      hasLoaded = true;
    });

    // Animate elements
    function animate() {
      if (!hasLoaded) return;

      // Update text translate-values
      const textOffset = vmax * -0.05;
      let textOffsetX = 0;
      let textOffsetY = 0;

      if (mouse.continuousAnimation) {
        textOffsetX = mouse.relX * textOffset;
        textOffsetY = mouse.relY * textOffset;
      }

      textX = lerp(textX, textOffsetX, lerpFactor);
      textY = lerp(textY, textOffsetY, lerpFactor);

      root.style.setProperty('--text-x', textX + 'px');
      root.style.setProperty('--text-y', textY + 'px');

      for (const img of images) {
        // Distance to mouse
        const deltaX = img.sprite.x - mouse.x;
        const deltaY = img.sprite.y - mouse.y;
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        img.distance = distance;

        // Scale
        let scaleFactor = 0;
        if (distance < minScaleDistance && mouse.continuousAnimation) {
          scaleFactor = 1 - distance / minScaleDistance;
        }

        // Reduce scale factor
        scaleFactor = Math.min(0.12, scaleFactor);

        const maxScale = 1.75;

        img.sprite.width = lerp(
          img.sprite.width,
          (img.scale + (maxScale - img.scale) * scaleFactor) * vmax,
          lerpFactor
        );
        // img.sprite.width = img.scale * vmax;
        img.sprite.height = img.sprite.width / img.ratio;

        // Position
        const positionFactor = 1 - distance / minPositionDistance;
        const offset = vmax * 0.75;

        let offsetX = 0;
        let offsetY = 0;
        if (mouse.continuousAnimation) {
          offsetX = -offset * mouse.relX * positionFactor;
          offsetY = -offset * mouse.relY * positionFactor;
        }

        img.oX = w * img.x;
        img.oY = h * img.y;
        img.sprite.x = lerp(img.sprite.x, img.oX + offsetX, lerpFactor);
        img.sprite.y = lerp(img.sprite.y, img.oY + offsetY, lerpFactor);
      }

      // Sort elements by distance, set z-index
      images.sort((a, b) => b.distance - a.distance);
      images.forEach((img, i) => {
        img.sprite.zIndex = i;
      });
    }

    // Pixi anim loop
    app.ticker.add(deltaTime => {
      animate();
    });
  });
}

export default init;
