import { $ } from '../helpers/query-selector.js';
import { gsap, ScrollTrigger, ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const root = $('.js-order-button');
const form = $('.js-order-form');
const button = $('a', root);

function init() {
  if (!root) return;

  ScrollTrigger.create({
    trigger: root,
    start: '+=80px bottom',
    endTrigger: form ?? $('.js-footer'),
    end: 'top bottom',
    toggleClass: 'c-order-button--sticky'
  });

  if (form) {
    button.addEventListener('click', e => {
      e.preventDefault();
      gsap.to(window, { duration: 1, scrollTo: form });
    });
  }
}

export default init;
