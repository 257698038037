// Imported in /gulpfile.js/tasks/rollup.js:L27
// Not ideal solution :(

function init() {
  if (__Module__) return;
  if ('fetch' in window) return;

  const el = document.querySelector('div[data-legacy-warning]');
  if (!el) return;

  const text = JSON.parse(el.dataset.legacyWarning).html;
  const p = document.createElement('p');
  p.classList.add('c-legacy-warning');
  p.innerHTML = text;
  document.body.insertBefore(p, document.body.firstChild);
}

init();
